<template>
  <component
    :is="as"
    class="text-center inline-flex items-center justify-center rounded border focus:outline-none focus:ring-2 focus:ring-offset-2"
    :class="classes"
    v-bind="{ ...$attrs }"
  >
    <slot />
  </component>
</template>
<script>
export default {
  inheritAttrs: false,
}
</script>
<script setup>
import { computed } from "vue"

const props = defineProps({
  xs: {
    type: Boolean,
    default: false,
  },
  sm: {
    type: Boolean,
    default: false,
  },
  base: {
    type: Boolean,
    default: true,
  },
  lg: {
    type: Boolean,
    default: false,
  },
  xl: {
    type: Boolean,
    default: false,
  },
  primary: {
    type: Boolean,
    default: false,
  },
  secondary: {
    type: Boolean,
    default: false,
  },
  white: {
    type: Boolean,
    default: false,
  },
  link: {
    type: Boolean,
    default: false,
  },

  asHyperlink: {
    type: Boolean,
    default: false,
  },
})

const TYPE_PRIMARY = "primary"
const TYPE_SECONDARY = "secondary"
const TYPE_WHITE = "white"
const TYPE_LINK = "link"

const SIZE_XS = "xs"
const SIZE_SM = "sm"
const SIZE_BASE = "base"
const SIZE_LG = "lg"
const SIZE_XL = "xl"

const sizeClasses = {
  [SIZE_XS]: "px-2.5 py-1.5 text-xs font-medium",
  [SIZE_SM]: "px-3 py-2 text-sm font-medium leading-4",
  [SIZE_BASE]: "px-4 py-2 text-sm font-medium",
  [SIZE_LG]: "px-4 py-2 text-base font-medium",
  [SIZE_XL]: "px-6 py-3 text-base font-medium",
}

const typeClasses = {
  [TYPE_PRIMARY]:
    "shadow-sm  border-transparent bg-cerulean-600 text-hc-cerulean-600 hover:bg-cerulean-700 hover:text-hc-cerulean-700 focus:ring-cerulean-500",
  [TYPE_SECONDARY]:
    "shadow-sm  border-transparent bg-cerulean-100 text-hc-cerulean-100 hover:bg-cerulean-200 text-hc-cerulean-200 focus:ring-cerulean-500",
  [TYPE_WHITE]:
    "shadow-sm  border-gray-300 bg-white text-gray-700 hover:bg-gray-50 hover:text-gray-800 focus:ring-cerulean-500",
  [TYPE_LINK]:
    "border-transparent bg-white text-cerulean-700 hover:bg-gray-50 focus:ring-cerulean-500",
}

const size = computed(() => {
  if (props[SIZE_XS]) return SIZE_XS
  if (props[SIZE_SM]) return SIZE_SM
  if (props[SIZE_LG]) return SIZE_LG
  if (props[SIZE_XL]) return SIZE_XL

  return SIZE_BASE
})

const type = computed(() => {
  if (props[TYPE_PRIMARY]) return TYPE_PRIMARY
  if (props[TYPE_SECONDARY]) return TYPE_SECONDARY
  if (props[TYPE_LINK]) return TYPE_LINK

  return TYPE_WHITE
})

const classes = computed(() => {
  return sizeClasses[size.value] + " " + typeClasses[type.value]
})

const as = computed(() => {
  return props.asHyperlink ? "a" : "button"
})
</script>
